import {
  autoUpdate,
  computePosition,
  offset,
  shift,
  size,
} from '@floating-ui/dom';
import { v4 as uuidv4 } from 'uuid';
import { createElement, defineModule } from '@/js/utils/helpers';

const selects: ReturnType<typeof createSelect>[] = [];

const getElements = () => ({
  selectElements: document.querySelectorAll<HTMLSelectElement>(
    '.frm_form_field select:not([multiple])',
  ),
});

const createSelect = (originalSelectElement: HTMLSelectElement) => {
  /**
   * On touch devices we use the native select element
   */
  originalSelectElement.classList.add('no-touch:!hidden');

  const id = uuidv4();
  let isOpen = false;

  /* ------------------------------ Create Markup ----------------------------- */
  const selectElementText = createElement(
    'span',
    {
      className: 'custom-select__text',
    },
    [
      [...originalSelectElement.options].find((option) => option.selected)
        ?.textContent ?? '',
    ],
  );

  const selectElement = createElement(
    'button',
    {
      ariaExpanded: false,
      className: 'custom-select__control',
      type: 'button',
    },
    [selectElementText],
  );
  selectElement.setAttribute('aria-controls', `custom-select-${id}`);

  const selectListElement = createElement('div', {
    id: `custom-select-${id}`,
    role: 'listbox',
    ariaHidden: true,
    ariaHasPopup: true,
    className: 'custom-select__list',
  });

  const selectOptionElements = [...originalSelectElement.options].map(
    (option, i) => {
      const isPlaceholder = option.hasAttribute('data-placeholder');

      const optionElement = createElement(
        'button',
        {
          role: 'option',
          type: 'button',
          ariaSelected: option.selected,
          hidden: option.defaultSelected && i === 0,
          className: `custom-select__option ${isPlaceholder ? 'custom-select__option--placeholder' : ''}`,
          value: option.value,
          tabIndex: 0,
          ...(option.disabled || isPlaceholder ? { disabled: true } : {}),
        },
        [option.textContent ?? ''],
      );

      return optionElement;
    },
  );
  selectListElement.append(...selectOptionElements);

  originalSelectElement.parentElement?.insertBefore(
    createElement(
      'div',
      {
        className: 'custom-select',
      },
      [selectElement, selectListElement],
    ),
    originalSelectElement.nextSibling,
  );
  /* -------------------------------------------------------------------------- */

  const toggleListElement = (force?: boolean) => {
    force = force ?? !isOpen;
    selectElement.ariaExpanded = `${force}`;
    selectListElement.ariaHidden = `${!force}`;
    isOpen = force;
  };

  /* -------------------------- Setup Event Listeners ------------------------- */
  const handleOriginalSelectElementInput = (e: Event) => {
    if (!(e.target instanceof HTMLSelectElement)) return;

    selectOptionElements.forEach((optionElement) => {
      if (!(e.target instanceof HTMLSelectElement)) return;

      // eslint-disable-next-line eqeqeq
      const isSelected = optionElement.value == e.target.value;
      optionElement.setAttribute('aria-selected', `${isSelected}`);

      if (isSelected) {
        originalSelectElement.value = optionElement.value;
        selectElementText.textContent = optionElement.textContent;
      }
    });
  };
  originalSelectElement.addEventListener(
    'input',
    handleOriginalSelectElementInput,
    { passive: true },
  );

  const handleSelectElementClick = () => toggleListElement();
  selectElement.addEventListener('click', handleSelectElementClick, {
    passive: true,
  });

  const handleSelectElementOutsideClick = (e: Event) => {
    if (!(e.target instanceof HTMLElement) || e.target === selectElement) {
      return;
    }

    toggleListElement(false);
  };
  window.addEventListener('click', handleSelectElementOutsideClick, {
    passive: true,
  });

  const handleEscapeKeyPress = (e: KeyboardEvent) => {
    if (e.key !== 'Escape' || !isOpen) return;

    toggleListElement(false);
  };
  window.addEventListener('keydown', handleEscapeKeyPress, { passive: true });

  const handleOptionElementClick = (e: Event) => {
    if (!(e.target instanceof HTMLButtonElement)) return;

    selectOptionElements.forEach((optionElement) => {
      const isSelected = optionElement === e.target;
      optionElement.setAttribute('aria-selected', `${isSelected}`);
      if (!isSelected) return;

      originalSelectElement.value = optionElement.value;
      selectElementText.textContent = optionElement.textContent;

      // Not sure if this is needed with the jQuery trigger below, keeping it anyway
      originalSelectElement.dispatchEvent(new Event('change'));

      // This makes it so Formidable Forms can detect the change
      jQuery(originalSelectElement).trigger('change');
    });
  };
  selectOptionElements.forEach((optionElement) => {
    optionElement.addEventListener('click', handleOptionElementClick, {
      passive: true,
    });
  });
  /* -------------------------------------------------------------------------- */

  /* ---------------------------- Setup FloatingUI ---------------------------- */
  const updatePosition = () => {
    computePosition(selectElement, selectListElement, {
      placement: 'bottom-start',
      middleware: [
        offset(6),
        size({
          apply({ rects, elements }) {
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
          },
        }),
        shift(),
      ],
    }).then(({ x, y }) => {
      Object.assign(selectListElement.style, {
        left: `${x}px`,
        top: `${y}px`,
      });
    });
  };

  const cleanupAutoUpdate = autoUpdate(
    selectElement,
    selectListElement,
    updatePosition,
  );
  /* -------------------------------------------------------------------------- */

  return {
    /**
     * Removes all event listeners
     */
    cleanup() {
      selectElement.removeEventListener('click', handleSelectElementClick);
      window.removeEventListener('click', handleSelectElementOutsideClick);
      selectElement.removeEventListener(
        'input',
        handleOriginalSelectElementInput,
      );
      window.removeEventListener('keydown', handleEscapeKeyPress);
      selectOptionElements.forEach((optionElement) => {
        optionElement.removeEventListener('click', handleOptionElementClick);
      });

      cleanupAutoUpdate();
    },
  };
};

export default defineModule(
  () => {
    const { selectElements } = getElements();

    selectElements.forEach((selectElement) =>
      selects.push(createSelect(selectElement)),
    );
  },
  () => {
    while (selects.length > 0) {
      selects.pop()?.cleanup();
    }
  },
);
