import type { createAccordion } from '@/js/utils/accordion';
import { defineModule } from '@/js/utils/helpers';

const accordions: ReturnType<typeof createAccordion>[] = [];

const getElements = () => ({
  accordionElements: document.querySelectorAll<HTMLElement>(
    '.content-block--accordion .accordion',
  ),
});

export default defineModule(
  async () => {
    const { accordionElements } = getElements();
    if (!accordionElements.length) return;

    const { createAccordion } = await import('@/js/utils/accordion');

    accordionElements.forEach((accordionElement) => {
      const accordion = createAccordion(accordionElement, {
        onToggle: (open) => {
          if (!open) return;

          accordions.forEach((otherAccordion) => {
            if (accordionElement === otherAccordion.el) return;

            otherAccordion.close();
          });
        },
      });

      accordions.push(accordion);
    });
  },
  () => {
    while (accordions.length) {
      accordions.pop()?.destroy();
    }
  },
);
