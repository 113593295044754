import type Swiper from 'swiper';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const swipers: Swiper[] = [];

const getElements = () => ({
  swiperElements: document.querySelectorAll<HTMLElement>(
    '.content-block--team-slider .swiper',
  ),
});

export default defineModule(async () => {
  const { swiperElements } = getElements();
  if (!swiperElements) return;

  const [{ Swiper }, { Navigation }] = await Promise.all([
    import('swiper/core'),
    import('swiper/modules'),
  ]);

  swiperElements.forEach((swiperElement) => {
    const swiper = new Swiper(swiperElement, {
      modules: [Navigation],
      slidesPerView: 'auto',
      spaceBetween: 10,
      grabCursor: true,
      centeredSlides: true,
      centeredSlidesBounds: true,
      navigation: {
        prevEl: swiperElement
          .closest('.content-block')
          ?.querySelector<HTMLElement>('.swiper-button-prev')!,
        nextEl: swiperElement
          .closest('.content-block')
          ?.querySelector<HTMLElement>('.swiper-button-next')!,
      },
      breakpoints: {
        [BREAKPOINTS.lg]: {
          centeredSlides: false,
        },
      },
    });

    swipers.push(swiper);
  });
});
